import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import { Container } from '../Grid';
import Icon from '../Icon';
import Image from '../Image';
import Share from '../Share';
import Subscribe from '../Subscribe';
import './styles.scss';


/**
 * A global header component that is populated with properties from `gatsby-config.js`
 */

const Header = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isOffPage, setIsOffPage] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(
    typeof window !== `undefined` ? window.innerWidth : null
  );

  const header = useRef(null);
  const headerUpper = useRef(null);
  const headerLower = useRef(null);
  const logo = useRef(null);
  const partnerLogos = useRef(null);
  const menu = useRef(null);

  const { pathname: currentPath } = useLocation();

  const isHome = currentPath === '/';

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          partnerName
          partnerLogo
          programName
          programLogo
          partnerUrl
          menuLinks {
            label
            path
            children {
              label
              path
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const threshold = 80;
    let ticking = false;
    let lastScrollY = window.pageYOffset;

    const mobileMenuHeight = isMobileMenuVisible
      ? menu.current.offsetHeight
      : 0;
    const headerHeight = header.current.offsetHeight + mobileMenuHeight;
    const headerUpperStyles = window.getComputedStyle(header.current);
    const headerUpperPaddingTop = parseInt(
      headerUpperStyles.getPropertyValue('padding-top'),
      10
    );
    const headerUpperPaddingBottom = parseInt(
      headerUpperStyles.getPropertyValue('padding-bottom'),
      10
    );

    // This is the height of the top utility bar. which is hidden
    // in sticky mode.
    const stickyHeaderOffset =
      headerUpper.current.offsetHeight +
      headerUpperPaddingTop +
      headerUpperPaddingBottom;

    if (isSticky) {
      header.current.style.transform = `translateY(-${stickyHeaderOffset}px)`;
    } else {
      header.current.style.transform = '';
    }

    const toggleHeader = () => {
      const scrollY = window.pageYOffset;

      // If we are past the header
      if (scrollY > headerHeight) {
        // Mark the fact the we are below the header
        if (!isOffPage) setIsOffPage(true);
        // If we are scrolling up OR down mobile gets hidden
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);

        // If the scroll length was less then threshold, ignore the scroll.
        // This prevents very slight scrolls from triggering animations.
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return; // RETURN EARLY
        }
      }

      // We are moving DOWN
      if (scrollY > lastScrollY) {
        if (isSticky) setIsSticky(false);
      } else if (scrollY > stickyHeaderOffset && scrollY < lastScrollY) {
        // We are moving UP, and we are still below the utility nav
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);
        if (!isSticky) setIsSticky(true);
      }

      // If we are near the top utility nav
      // the header should always show.
      if (scrollY <= stickyHeaderOffset) {
        if (isOffPage) setIsOffPage(false);
        if (isSticky) setIsSticky(false);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(toggleHeader);
        ticking = true;
      }
    };

    const handleResize = () => {
      if (typeof window !== `undefined`) {
        setViewportWidth(window.innerWidth);

        if (window.innerWidth >= 1200 && isMobileMenuVisible) {
          setIsMobileMenuVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isSticky, isOffPage, isMobileMenuVisible, viewportWidth]);

  const handleHamburgerClick = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleSubmenuToggle = (e, path) => {
    e.preventDefault();
    setActiveSubMenu((currentPath) => (currentPath === path ? null : path));
  };

  const renderPartnerLogos = (scaled, hidden) => (
    <div
      className={classNames(
        'header__partner-logos',
        (hidden || (!isHome && isSticky)) && 'header__partner-logos--hidden'
      )}
      ref={partnerLogos}
    >
      {/* DE logo */}
      <div
        className={classNames(
          'header__de-logo',
          scaled && 'header__de-logo--scaled'
        )}
      >
        <Image
          className="header__de-logo__img"
          filename={isHome ? 'de-logo-white.svg' : 'logo-partner-DE.svg'}
          alt="Discovery Education"
        />
      </div>
      {/* Partner logo */}
      <div className="header__logo-divider-div">
        <div
          className={classNames(
            'header__logo-divider',
            isHome && 'header__logo-divider--white',
            !isHome && 'header__logo-divider--gray'
          )}
        />
      </div>
      <div
        className={classNames(
          'header__partner-logo',
          scaled && 'header__partner-logo--scaled'
        )}
      >
        <Image
          className="header__partner-logo__img"
          filename={
            isHome ? 'logo-header-CMA-white.svg' : 'logo-partner-CMA.svg'
          }
          alt={site.siteMetadata.partnerName}
        />
      </div>
    </div>
  );

  const renderUtilityMenu = () => (
    <div className="header__utility-menu">
      <div className="header__signup">
        <Subscribe
          trigger={
            <Button className="header__signup-button" link>
              Updates <Icon name="signup" marginLeft />
            </Button>
          }
        />
      </div>
      <div className="header__share">
        <Share inDropDown />
      </div>
    </div>
  );

  // const renderMainMenu = (links, parent = null) => {
  //   return (
  //     <ul
  //       className={classNames(
  //         parent ? 'header__main-menu-sublist' : 'header__main-menu-list',
  //         parent &&
  //           activeSubMenu === parent &&
  //           'header__main-menu-sublist--open'
  //       )}
  //     >
  //       {links.map((link, i) => (
  //         <li
  //           key={i}
  //           className={classNames(
  //             parent
  //               ? 'header__main-menu-sublist-item'
  //               : 'header__main-menu-list-item',
  //             activeSubMenu === link.path &&
  //               (parent
  //                 ? 'header__main-menu-sublist-item--active'
  //                 : 'header__main-menu-list-item--active')
  //           )}
  //         >
  //           <Link
  //             activeClassName="header__main-menu-link--active"
  //             to={link.path}
  //             onClick={
  //               link.children && ((e) => handleSubmenuToggle(e, link.path))
  //             }
  //             className={
  //               parent
  //                 ? 'header__main-menu-sublist-link'
  //                 : 'header__main-menu-link'
  //             }
  //           >
  //             {link.label}{' '}
  //             {link.children && (
  //               <Icon
  //                 name="caratdown"
  //                 className={classNames(
  //                   'header__main-menu-carat',
  //                   activeSubMenu === link.path &&
  //                     'header__main-menu-carat--rotated'
  //                 )}
  //                 marginLeft
  //               />
  //             )}
  //           </Link>
  //           {link.children && renderMainMenu(link.children, link.path, link.newItem)}
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };
  const renderMainMenu = (links, parent = null) => {
    return (
      <ul
        className={classNames(
          parent ? 'header__main-menu-sublist' : 'header__main-menu-list',
          parent &&
            activeSubMenu === parent &&
            'header__main-menu-sublist--open'
        )}
      >
        {links.map((link, i) => (
          <li
            key={i}
            className={classNames(
              parent
                ? 'header__main-menu-sublist-item'
                : 'header__main-menu-list-item',
              activeSubMenu === link.path &&
                (parent
                  ? 'header__main-menu-sublist-item--active'
                  : 'header__main-menu-list-item--active')
            )}
          >
            <Link
              activeClassName="header__main-menu-link--active"
              to={link.path}
              onClick={
                link.children && ((e) => handleSubmenuToggle(e, link.path))
              }
              className={
                parent
                  ? 'header__main-menu-sublist-link'
                  : 'header__main-menu-link'
              }
            >
              {link.label}{' '}
              {link.label === 'Anatomy of a Hit Song' && <span className='new-badge'></span>}
              {link.children && (
                <Icon
                  name="caratdown"
                  className={classNames(
                    'header__main-menu-carat',
                    activeSubMenu === link.path &&
                      'header__main-menu-carat--rotated'
                  )}
                  marginLeft
                />
              )}
            </Link>
            {link.children && renderMainMenu(link.children, link.path, link.newItem)}
          </li>
        ))}
      </ul>
    );
  };


  return (
    <div>
      <header
        className={classNames('header', isOffPage && 'header--off-page')}
        ref={header}
      >
        <Container noPadding fullWidth>
          <div
            className={classNames(
              'header__upper',
              isHome && 'header__upper--home'
            )}
            ref={headerUpper}
          >
            <Container fullWidth>
              <div className="header__upper-content">
                {renderPartnerLogos(true, isHome)}
                {renderUtilityMenu()}
              </div>
            </Container>
          </div>
        </Container>
        <div className="header__lower" ref={headerLower}>
          <Container fullWidth>
            <div className="header__lower-content">
              <div className="header__logo-container">
                {isHome ? renderPartnerLogos(false) : ''}
              </div>
              <div className="header__hamburger">
                <Button
                  link
                  className={classNames(
                    'header__hamburger-button',
                    isMobileMenuVisible && 'header__hamburger-button--active',
                    isSticky && 'header__hamburger-button--in-sticky'
                  )}
                  onClick={handleHamburgerClick}
                >
                  <div className="header__hamburger-button-inner"></div>
                </Button>
              </div>
              <div
                className={classNames(
                  'header__collapse',
                  isMobileMenuVisible && 'header__collapse--visible'
                )}
                ref={menu}
              >
                <nav className="header__main-menu">
                  {renderMainMenu(site.siteMetadata.menuLinks, null
                    )}
                </nav>
                {isMobileMenuVisible && renderUtilityMenu()}
              </div>
            </div>
          </Container>
        </div>
      </header>
    </div>
  );
};

export default Header;
