import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import useImageQuery from '../../hooks/useImageQuery';

/**
 * The `Image` component uses gatsby-plugin-image to generate optimized images. Learn more at https://www.gatsbyjs.com/docs/how-to/images-and-media/using-gatsby-plugin-image/.
 */
const Image = ({ filename, alt, objectFit = 'cover', ...other }) => {
  const { findImageNodeByPath, findImageNodeByFilename } = useImageQuery();
  if (filename.startsWith('https://') || filename.startsWith('http://')) {
    return <img src={filename} alt={alt} {...other} />;
  }

  let imageNode = findImageNodeByPath(filename);
  if (!imageNode) {
    imageNode = findImageNodeByFilename(filename);
  }

  if (!imageNode) {
    // No matching file found.
    return null;
  }

  // Handle edge case images
  if (!imageNode.childImageSharp || imageNode.extension === 'svg') {
    return <img alt={alt} src={imageNode.publicURL} {...other} />;
  }

  // Most images return here.
  return (
    <GatsbyImage
      image={getImage(imageNode)}
      loading="eager"
      alt={alt}
      objectFit={objectFit}
      {...other}
    />
  );
};

Image.defaultProps = {
  alt: '',
};

Image.propTypes = {
  /** A filename or filepath relative to /src/images/ */
  filename: PropTypes.string.isRequired,
  /** A text description of the image */
  alt: PropTypes.string,
};

export default Image;
