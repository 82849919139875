import React from 'react';

const Share = (props) => (
  <svg viewBox="0 0 25 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4087 0.0908266C13.7643 -0.0720282 14.1822 -0.0126254 14.4783 0.24287L24.2988 8.71571C24.5112 8.89903 24.6369 9.16318 24.6451 9.44368C24.6533 9.72417 24.5432 9.99521 24.3418 10.1906L14.5214 19.7178C14.233 19.9975 13.8053 20.0775 13.4354 19.921C13.0654 19.7644 12.8251 19.4017 12.8251 19V14.0701C6.5982 14.3461 3.38763 17.1819 2.58292 19.3482C2.41437 19.802 1.94502 20.0699 1.46861 19.9842C0.992196 19.8986 0.645508 19.4841 0.645508 19C0.645508 13.2325 2.57785 9.86338 5.36208 8.00158C7.71198 6.43021 10.519 6.04171 12.8251 5.96713V1.00001C12.8251 0.608899 13.0531 0.253681 13.4087 0.0908266ZM14.8251 3.18354V6.95183C14.8251 7.50411 14.3773 7.95183 13.8251 7.95183C11.4331 7.95183 8.64991 8.20898 6.47382 9.66412C4.9018 10.7153 3.53248 12.4737 2.9475 15.5193C5.18572 13.532 8.84698 12.0482 13.8251 12.0482C14.3773 12.0482 14.8251 12.4959 14.8251 13.0482V16.6366L22.1648 9.51609L14.8251 3.18354Z"
    />
  </svg>
);

export default Share;
