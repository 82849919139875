import React from 'react';

const Signup = (props) => (
  <svg viewBox="0 0 25 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.645508 3C0.645508 1.34315 1.98865 0 3.64551 0H21.6456C23.3025 0 24.6456 1.34315 24.6456 3V3.83117C24.6456 3.87234 24.6431 3.91292 24.6383 3.95279C24.542 5.49835 23.4932 6.83376 21.9943 7.28341L13.7949 9.74324C13.0451 9.96817 12.2459 9.96817 11.4961 9.74324L3.29668 7.28341C3.06862 7.21499 2.85097 7.12607 2.64551 7.01901V17C2.64551 17.5523 3.09322 18 3.64551 18H5.35148C5.90377 18 6.35148 18.4477 6.35148 19C6.35148 19.5523 5.90377 20 5.35148 20H3.64551C1.98865 20 0.645508 18.6569 0.645508 17V3ZM2.64551 3.72015C2.64551 4.47979 3.14378 5.14948 3.87138 5.36776L12.0708 7.82759C12.4457 7.94005 12.8453 7.94005 13.2202 7.82759L21.4196 5.36776C22.1472 5.14948 22.6455 4.47979 22.6455 3.72015C22.6455 2.77014 21.8754 2 20.9254 2H4.36566C3.41565 2 2.64551 2.77014 2.64551 3.72015ZM24.6455 15.5C24.6455 15.7745 24.5327 16.0369 24.3335 16.2258L20.6412 19.7258C20.2403 20.1057 19.6074 20.0888 19.2274 19.688C18.8475 19.2871 18.8644 18.6542 19.2652 18.2742L21.137 16.5H11.6455C11.0932 16.5 10.6455 16.0523 10.6455 15.5C10.6455 14.9477 11.0932 14.5 11.6455 14.5H21.137L19.2652 12.7258C18.8644 12.3458 18.8475 11.7129 19.2274 11.312C19.6074 10.9112 20.2403 10.8943 20.6412 11.2742L24.3335 14.7742C24.5327 14.9631 24.6455 15.2255 24.6455 15.5Z"
    />
  </svg>
);

export default Signup;
