import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/** A simple `section` element that provides some vertical spacing */

const Section = ({ className, children, noPadding, ...other }) => (
  <section
    className={classNames(
      'section',
      noPadding && 'section--noPadding',
      className
    )}
    {...other}
  >
    {children}
  </section>
);

Section.defaultProps = {
  className: null,
};

Section.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  /** If true, removes all padding */
  noPadding: PropTypes.bool,
};

export default Section;
